.footer {
	background: rgba(0, 0, 0, 0.04);
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	color: #8a89ab;
	padding: 26px 144px;
	width: 100%;
	margin-top: auto;
}

.footer a {
	color: #8a89ab;
}

.footer__cr {
	color: #8a89ab;
	text-decoration: none;
}

.footer .footer__links {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
}

.footer .footer__links p {
	margin: 0 5px;
}

.footer p {
	margin: 0 5px;
}

@media screen and (min-width: 960px) {
	.footer {
		margin-top: auto;
	}
}

@media screen and (max-width: 740px) {
	.footer {
		padding: 12px 40px;
	}
}

@media screen and (max-width: 540px) {
	.footer {
		flex-direction: column-reverse;
	}
	.footer .footer__links {
		margin-bottom: 10px;
	}
}
