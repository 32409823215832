.exampleMessage {
	display: flex;
	padding: 12px 16px;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	gap: 8px;
	align-self: stretch;
	border-radius: 12px;
	border: 1px solid #38375a;
	background: #292841;
	position: relative;
}

.text {
	color: #a4a2c6;
	font-family: Inter;
	font-size: 15px;
	font-style: normal;
	font-weight: 400;
	line-height: 20px;
	align-self: stretch;
	margin: 0;
	padding: 0;
	text-align: left;
	white-space: pre-wrap;
	word-break: break-all;
}

.delete {
	cursor: pointer;
	width: 24px;
	height: 24px;
	position: absolute;
	right: -10px;
	top: -10px;
}
