.dialog :global(.MuiPaper-root) {
	background: linear-gradient(0deg, #191827 0%, #191827 100%), #f1eeff;
	display: flex;
	flex-direction: column;
	font-family: 'Inter';
	align-items: center;
	color: white;
	text-align: center;
	width: 100%;
}

.dialog :global(.inbutton) {
	font-family: Inter;
	margin-top: 0 !important;
	margin: 0;
}

.container {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	width: 100%;
}

.content {
	padding: 20px;
	box-sizing: border-box;
	width: 100%;
}

.header {
	display: flex;
	width: 100%;
	justify-content: center;
	position: relative;
	background-color: #292841;
	padding: 20px 12px;
	box-sizing: border-box;
}

.back {
	margin-right: auto;
}

.title {
	color: #fff;
	font-family: Inter;
	font-size: 18px;
	font-weight: 900;
	line-height: 24px;
	letter-spacing: 0.37400001287460327px;
	text-align: center;
	margin: 0;
	margin-right: auto;
}

.block {
	width: 100%;
	margin-bottom: 32px;
}

.block.nameBlock {
	margin-bottom: 12px;
}

.block.publicBlock {
	margin-bottom: 0;
}

.name {
	border-radius: 20px;
	background: var(--Dark-Fields-Bg, #212034);
	box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.06);
	color: #e2dfef;
	text-align: center;
	font-family: Inter;
	font-size: 24px;
	font-style: normal;
	font-weight: 700;
	line-height: 30px;
	/* 125% */
	letter-spacing: 0.374px;
	height: 66px;
	width: 100%;
	box-sizing: border-box;
}

.name::placeholder {
	color: var(--Dark-Text-Placeholder-Alt, #565470);
}

.pronoun {
	position: initial !important;
	height: 36px;
	box-sizing: border-box;
	width: 100%;
}

.pronounBlock {
	border-radius: 12px;
	background: #12121b;
	padding: 4px;
	box-sizing: border-box;
}

.btn:global(.MuiButtonBase-root) {
	padding: 8px 30px;
	color: #9c85f6;
	font-family: Inter;
	font-size: 14px;
	font-style: normal;
	font-weight: 700;
	line-height: 18px;
	/* 128.571% */
	letter-spacing: 0.1px;
	border: none;
	width: 100%;
	height: 36px !important;
	box-sizing: border-box;
}

.btn:global(.MuiButtonBase-root):hover {
	border: none;
}

.btnSelected:global(.MuiButtonBase-root) {
	border-radius: 10px !important;
	background: #292841 !important;
	color: #fff;
}

.rows {
	display: flex;
	flex-direction: column;
	border-radius: 20px;
	background: var(--Dark-Fields-Bg, #212034);
}

.settingsRow {
	display: flex;
	width: 100%;
	padding: 12px 16px;
	box-sizing: border-box;
	align-items: center;
	cursor: pointer;
}

.voiceRow {
	border-radius: 12px;
	border: 1px solid #292841;
	background: #191827;
}

.settingsRow:not(:last-child) {
	border-bottom: 1px solid #292841;
}

.settingsText {
	color: #e2dfef;
	font-family: Inter;
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 24px;
	/* 150% */
	margin: 0;
	padding: 0;
	margin-left: 12px;
	text-align: left;
}

.settingsIcon {
	width: 32px;
	height: 32px;
}

.emptyText {
	color: #fd5862;
	font-size: 18px;
	font-style: normal;
	font-weight: 400;
	line-height: 24px;
	/* 133.333% */
	letter-spacing: 0.374px;
	margin-left: auto;
}

.progressContainer {
	position: relative;
	margin-left: auto;
}

.check {
	position: absolute;
	top: 45%;
	left: 50%;
	transform: translate(-50%, -50%);
}

.arrow {
	height: 20px;
	margin-left: 12px;
}

.error {
	font-size: 14px;
	color: red;
}

.createButton:global(.MuiButtonBase-root) {
	width: 100%;
	height: 64px !important;
	border-radius: 100px !important;
	margin-top: 32px !important;
	margin-bottom: 32px !important;
}

.voiceContainer {
	margin-left: auto;
	display: flex;
	align-items: center;
	gap: 12px;
	display: flex;
	width: fit-content;
	padding: 8px 16px;
	justify-content: space-between;
	border-radius: 12px;
	border: 1px solid #292841;
	background: #191827;
}
