.container {
	height: calc(100dvh - 64px);
}

.placeholder {
	width: 160px;
	height: 160px;
	border-radius: 12px;
}

.avatarSpinnerContainer {
	width: 160px;
	height: 164px;
	margin: auto;
}

.avatarContainer {
	position: relative;
	margin: 0 auto;
	display: flex;
	justify-content: space-around;
	width: 100%;
}

.refresh {
	position: absolute;
	bottom: -12px;
	right: -12px;
	cursor: pointer;
	width: 36px;
	height: 36px;
}

.buttonG {
	width: 100%;
	border-radius: 100px !important;
	height: 64px !important;
}

.textArea :global(.edit-bot__descriptions__suggestions) {
	flex-wrap: nowrap;
	overflow-x: auto;
	white-space: nowrap;
	display: block;
}

.textArea :global(.edit-bot__descriptions__suggestions) :global(.suggestion) {
	width: fit-content;
	text-wrap: nowrap;
	min-width: fit-content;
	max-width: none !important;
	margin-top: 0;
}

.imageContainer {
	position: relative;
	display: inline-block;
	cursor: pointer;
}

.selected::after {
	content: '';
	position: absolute;
	top: -5px;
	left: -5px;
	right: -5px;
	bottom: -2px;
	border: 2px solid #9c85f6;
	border-radius: 18px;
	z-index: 1;
}

.check {
	position: absolute;
	z-index: 100;
	top: 0;
	right: 0;
}

.btnsRow {
	display: flex;
	gap: 10px;
	width: 100%;
}

.buttonRefresh:global(.inbutton.MuiButton-root) {
	width: 100% !important;
	background: #282649;
	border: none;

	border-radius: 100px !important;
	height: 64px !important;
}
