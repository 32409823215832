.mobile-bot-page {
	display: flex;
	flex-direction: column;
	background: #212034;
	height: 100dvh;
}

.mobile-bot-page .header {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	margin: 0;
	background: linear-gradient(0deg, #292841 0%, #292841 100%),
		rgba(255, 255, 255, 0.9);
	padding: 10px 12px;
	align-items: center;
}

.mobile-bot-page .header .back {
	margin-left: 12px;
	cursor: pointer;
}

.mobile-bot-page .header .profile {
	display: flex;
	flex-direction: row;
	align-items: center;
	margin: 0 auto 0 20px;
	cursor: pointer;
}

.mobile-bot-page .header .profile .avatar {
	width: 38px;
	height: 38px;
	border-radius: 5px;
	margin-right: 10px;
}

.mobile-bot-page .header .profile .texts {
	display: flex;
	flex-direction: column;
}

.mobile-bot-page .header .profile .texts .name {
	color: #fff;
	font-family: Inter;
	font-size: 18px;
	font-style: normal;
	font-weight: 900;
	line-height: 24px;
	/* 133.333% */
	letter-spacing: 0.374px;
	margin: 0;
}

.mobile-bot-page .header .profile .texts .subtitle {
	font-weight: 500;
	font-size: 10px;
	line-height: 12px;
	letter-spacing: 0.02em;
	color: black;
}

.mobile-bot-page .content {
	overflow-x: auto;
	padding: 8px;

	display: block;
	/* flex-direction: column; */
	/* justify-content: flex-end; */
}

.mobile-bot-page .content .chat__message_bot {
	color: #cac8f2;

	font-family: Inter;
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 24px;
	background-color: #292841;
}

.mobile-bot-page .content .chat__message_user {
	color: #10101a;

	font-family: Inter;
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 24px;
	background: #c0bee2;
	margin-left: auto;
	margin-right: 0;
}

.mobile-bot-page .input-field {
	margin: auto auto 6px auto;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	background: #292841;
	width: 100%;
	padding: 12px;
	box-sizing: border-box;
}

@media screen and (min-width: 960px) {
	.mobile-bot-page .content {
		overflow-x: auto;
		padding: 0 0 8px 0;
	}
}

.mobile-bot-page .input-field .input {
	resize: none;
	color: white;
	background: #292841;
	border: none;
	width: calc(100% - 36px - 2 * 6px);
	font-weight: 400;
	font-size: 18px;
	line-height: 24px;
	margin-right: 6px;
	font-family: 'Inter', sans-serif;
}

.mobile-bot-page .input-field .input::placeholder {
	font-style: normal;
	font-weight: 400;
	font-size: 18px;
	line-height: 24px;
	letter-spacing: 0.374px;
	color: #8a89ab;
}

.mobile-bot-page .input-field .input:focus {
	outline: none;
	border: none;
}

.mobile-bot-page .input-field .input:focus-visible {
	outline: none;
	border: none;
}

.mobile-bot-page .input-field .input::-webkit-scrollbar {
	width: 0px;
	height: 0px;
}

.content::-webkit-scrollbar {
	width: 0px;
	height: 0px;
}

.content::-webkit-scrollbar-track {
	border-radius: 20px;
}

.content::-webkit-scrollbar-thumb {
	background-color: #191827;
	border: 1px solid #191827;
	border-radius: 20px;
}

.mobile-bot-page .chat__message_bot_typing {
	width: 64px;
}

.mobile-bot-page .chat__message_bot_typing .dot-flashing {
	margin-left: 18px;
}

.mobile-bot-page .actions {
	margin-left: auto;
	display: flex;
	position: relative;
}

.actions .action:not(:first-of-type) {
	margin-left: 12px;
}

.mobile-bot-page .action {
	box-sizing: border-box;
	padding: 10px;
	border-radius: 12px;
	background: rgba(0, 0, 0, 0.2);
	width: 44px;
	height: 44px;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
}

.actions__box {
	position: absolute;
	top: 160%;
	right: 0;
	padding: 20px;
	box-sizing: border-box;
	border-radius: 12px;
	z-index: 100;
	background-color: #292841;
	color: white;
	width: 232px;
}

.action__row {
	box-sizing: border-box;
	color: #cac8f2;
	font-family: Inter;
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 24px;
	text-align: center;
	display: flex;
}

.action__row:not(:first-of-type) {
	margin-top: 24px;
}

.action__row p {
	margin: 0;
	color: #9c85f6;
	font-family: Inter;
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 24px;
	/* 150% */
	margin-left: 12px;
}

.chat__message_photo {
	min-height: 200px;
	background-color: #191827 !important;
	position: relative;
	padding: 0;
	height: 300px;
}

.chat__message_video {
	background-color: #191827 !important;
	position: relative;
}

.chat-limited {
	height: 156px;
	width: 100dvw;
	border-radius: 16px;
	border: 1px solid rgba(255, 0, 0, 0.12);
	background: rgba(255, 0, 0, 0.08);
	display: flex;
	flex-direction: column;
	padding: 20px;
	box-sizing: border-box;
	align-items: center;
	justify-content: center;
}

.chat-limited__title {
	color: #cac8f2;
	text-align: center;
	font-family: Inter;
	font-size: 16px;
	font-style: normal;
	font-weight: 800;
	line-height: 24px;
	/* 150% */
	letter-spacing: 0.374px;
	margin: 0;
	padding: 0;
	margin-bottom: 10px;
}

.chat-limited__btn.inbutton {
	margin-top: 20px !important;
	width: 100%;
}

.action__row .action__row_text-red {
	color: #fd5862;
}

@media screen and (max-width: 560px) {
	.actions .action:not(:first-of-type) {
		margin-left: 4px;
	}

	.mobile-bot-page .header .profile {
		margin-left: 8px;
	}

	.mobile-bot-page .header .profile .texts .name {
		line-height: 18px;
	}

	.mobile-bot-page .header .back {
		margin-left: 0px;
	}
}
