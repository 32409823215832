.selector {
	cursor: pointer;
	user-select: none;
	position: absolute;
	bottom: -10px;
	right: 0;
}

.name {
	font-size: 14px;
	color: #111827;
	margin: 0;
}

.arrow {
	width: 20px;
	height: 20px;
	transition: transform 0.2s ease;
}

.arrowRevert {
	transform: rotate(180deg);
}

.container {
	position: absolute;
	top: calc(100% + 4px);
	right: 0;
	width: 100%;
	min-width: 200px;
	background: #292841;
	color: var(--Dark-Text-Normal, #e2dfef);
	border-radius: 18px;
	z-index: 10;
	overflow: hidden;
}

.row {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 11px 16px;
	cursor: pointer;
	font-family: Inter;
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 22px;
	/* 137.5% */
	letter-spacing: -0.408px;
}

.row:not(:last-child) {
	border-bottom: 1px solid #37364f;
}

.row:hover {
	background: #59579d;
}

.rowName {
	font-size: 14px;
}

.rowName p {
	margin: 0;
}

.rowSelectedImg {
	width: 20px;
	height: 20px;
}
