.container {
	display: flex;
	flex-direction: column;
	gap: 16px;
	padding: 16px;
	width: 100%;
	height: fit-content;
}

.textArea {
	width: 100%;
	min-height: 100px;
}

.continueButton {
	margin-top: auto !important;
}

.container {
	display: flex;
	flex-direction: column;
	gap: 10px;
}

.modal {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);

	border-radius: 30px;
	background-color: white;

	align-items: flex-start;
	overflow: auto;
	overflow-y: hidden;
	background: #191827;
	font-family: Inter;
	max-width: 800px;
	min-width: 300px;
	width: 80%;
	font-family: Inter;
	height: fit-content;
}

.modalContainer {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 40px;
	box-sizing: border-box;
	font-family: Inter;
	height: 100%;
	overflow-y: auto;
	width: 100%;
}

.container :global(.inbutton) {
	font-family: Inter !important;
}

.title {
	color: #fff;
	text-align: center;
	font-family: Inter;
	font-size: 32px;
	font-style: normal;
	font-weight: 900;
	line-height: 44px;
	/* 137.5% */
	letter-spacing: 0.374px;
}

.textArea :global(.edit-bot__descriptions__suggestions) {
	flex-wrap: nowrap;
	overflow-x: auto;
	white-space: nowrap;
	display: block;
	height: 60px;
	padding-bottom: 60px;
}

.textArea :global(.edit-bot__descriptions__suggestions)::-webkit-scrollbar {
	width: 0px;
	height: 8px;
}

.textArea
	:global(.edit-bot__descriptions__suggestions)::-webkit-scrollbar-track {
	border-radius: 20px;
}

.textArea
	:global(.edit-bot__descriptions__suggestions)::-webkit-scrollbar-thumb {
	background-color: #191827;
	border: 1px solid #191827;
	border-radius: 20px;
}

.textArea :global(.edit-bot__descriptions__suggestions) :global(.suggestion) {
	width: fit-content;
	text-wrap: nowrap;
	min-width: fit-content;
	max-width: none !important;
}

.dialogMobile {
	padding: 0;
	display: flex;
	flex-direction: column;
	gap: 10px;
}
