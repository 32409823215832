.selector {
	display: flex;
	padding: 8px 20px;
	align-items: center;
	width: fit-content;
	margin-left: auto;

	background: #fff;
	box-shadow: 0px 8px 20px 0px rgba(36, 22, 91, 0.05);
	box-sizing: border-box;

	font-size: 17px;
	font-family: Montserrat;
	font-weight: 500;
	line-height: 24px;

	position: relative;

	font-family: Inter;
	cursor: pointer;

	border-radius: 12px;
	border: 1px solid #292841;
	background: #191827;
	color: #fff;
}

.arrow {
	margin-left: 0;
}
.arrowRevert {
	transform: rotate(180deg);
}

.container {
	position: absolute;
	padding: 16px;
	box-sizing: border-box;

	top: calc(100% + 8px);
	left: 0;
	width: 100%;
	z-index: 1;

	border-radius: 16px;
	background: #191827;
	box-shadow: 0px 8px 20px 0px rgba(36, 22, 91, 0.05);
	box-sizing: border-box;

	max-height: 420px;
	overflow-y: auto;
	overflow-x: hidden;

	top: auto;
	bottom: calc(100% + 8px);
	max-height: none;

	gap: 12px;
	display: flex;
	flex-direction: column;
}

.option {
	display: flex;
}

.row {
	box-sizing: border-box;
	width: 100%;
	display: flex;
	flex-direction: row;
	align-items: center;
	line-height: 32px;
	cursor: pointer;
	gap: 12px;
}

.rowSelectedImg {
	margin-left: auto;
}

.rowName {
	margin: 0;
	display: flex;
}

.rowName p {
	margin: 0;
}

.rowName span {
	font-size: 32px;
	margin-right: 20px;
}

.rowNameSelected p {
	font-weight: 700;
}

.icon {
	margin-right: 12px;
}

.name {
	color: #fff;
	margin-left: auto;
	margin-right: 12px;
}
