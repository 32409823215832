body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
		'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	background: linear-gradient(0deg, #292841 0%, #292841 100%),
		rgba(255, 255, 255, 0.9);
}

#root {
	background: linear-gradient(0deg, #292841 0%, #292841 100%),
		rgba(255, 255, 255, 0.9);

	--sidebar-width: 280px;
	--max-chat-width: 960px;
	min-height: 100dvh;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
		monospace;
}

* {
	/* outline: 1px solid red; */
	box-sizing: border-box;
}

.scroll-lock {
	overflow: hidden;
}
