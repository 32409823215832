.create-btn.inbutton.MuiButton-root {
	width: calc(100% - var(--margin) * 2);
	height: 56px;
}

.create-btn.inbutton.MuiButton-root p {
	padding: 0;
	margin-left: 12px;
	font-size: 16px;
}

.create-btn-star {
	width: 100%;
	aspect-ratio: 1;
	flex-shrink: 0;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	border-radius: 16px;
	border: 1px solid rgba(255, 255, 255, 0.1);
	background: #212034;
	background-image: url(./images/bg.png);
	background-size: cover;
	background-position: center;
}

.create-btn-star-text {
	color: #fff;
	text-align: center;
	font-size: 16px;
	font-style: normal;
	font-weight: 900;
	line-height: 20px; /* 125% */
	margin: 20px 0 0 0;
}
