.container {
	display: flex;
	flex-direction: column;
	gap: 10px;
}

.modal {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);

	border-radius: 30px;
	background-color: white;

	align-items: flex-start;
	overflow: auto;
	background: #191827;
	font-family: Inter;
	max-width: 800px;
	min-width: 300px;
	width: 80%;
	font-family: Inter;
}

.container {
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 40px;
	box-sizing: border-box;
	font-family: Inter;
	height: 100%;
	overflow-y: auto;
}

.title {
	color: #fff;
	text-align: center;
	font-family: Inter;
	font-size: 32px;
	font-style: normal;
	font-weight: 900;
	line-height: 48px; /* 150% */
	letter-spacing: 0.374px;
	margin: 0;
	margin-bottom: 40px;
}

.btn {
	width: 100% !important;
	font-family: Inter !important;
	font-size: 20px !important;
	margin-top: 20px !important;
}
