.modal {
	display: flex;
	align-items: center;
	justify-content: center;
}

.modal {
	max-width: 720px;
	height: fit-content;
	margin: auto;
	border-radius: 20px;
	background: #191827;
	box-sizing: border-box;
}

.modalContent {
	background: #191827;
	border-radius: 16px;
	padding: 24px;
	width: 90%;
	max-width: 600px;
	max-height: 90vh;
	overflow-y: auto;
}

.mobileContent {
	padding: 16px;
	height: 100%;
	display: flex;
	flex-direction: column;
}

.title {
	color: #fff;
	text-align: center;
	font-family: Inter;
	font-size: 32px;
	font-style: normal;
	font-weight: 900;
	line-height: 44px; /* 137.5% */
	letter-spacing: 0.374px;
	margin: 0;
	margin-bottom: 40px;
	display: flex;
	justify-content: center;
	align-items: baseline;
	gap: 10px;
}

.titleSpan {
	color: #5a5976;
	text-align: center;
	font-family: Inter;
	font-size: 24px;
	font-style: normal;
	font-weight: 400;
	line-height: 32px; /* 133.333% */
	letter-spacing: 0.374px;
}

.tagsList {
	display: flex;
	flex-wrap: wrap;
	gap: 12px;
	margin: 24px 0;
	justify-content: center;
}

.tag {
	padding: 10px 20px;
	border-radius: 24px;
	background-color: #2a2a33;
	cursor: pointer;
	transition: all 0.2s ease;
	font-size: 14px;
	color: #fff;
	font-style: normal;
	font-weight: 700;
	letter-spacing: 0.5px;
	border: 2px solid transparent;
}

.tag.selected {
	border: 2px solid var(--Dark-Main-Color, #7b5df3);
	box-sizing: border-box;
}

.doneButton {
	width: 100%;
	padding: 12px;
	background-color: #6b4eff;
	border: none;
	border-radius: 12px;
	color: white;
	font-size: 16px;
	font-weight: 600;
	cursor: pointer;
	margin-top: auto;
	margin-bottom: 20px;
}

.doneButton:hover {
	background-color: #5b3eef;
}

h2 {
	color: white;
	margin: 0;
	font-size: 24px;
	font-weight: 600;
}
