.content {
	padding: 0 0 20px 0;
	display: flex;
	flex-direction: column;
	gap: 20px;
}

.bio {
	width: 100%;
	min-height: 150px;
	border-radius: 20px;
	border: 0px solid var(--Dark-Main-Bg, #222134);
	background: linear-gradient(0deg, #212034 0%, #212034 100%), #191827;
	box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.06);
	color: #e2dfef;
	font-family: Inter;
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 24px;
	/* 160% */
	letter-spacing: 0.1px;
	height: 100%;
	box-sizing: border-box;
	width: 100%;
}

.bio::placeholder {
	color: var(--Dark-Text-Placeholder-Alt, #565470);
}

.tagsSection {
	display: flex;
	gap: 10px;
	flex-wrap: wrap;
}

.tagsButton {
	align-self: flex-start;
	border-radius: 30px !important;
	height: fit-content !important;
	font-size: 14px !important;
}

.tagsList {
	display: flex;
	flex-wrap: wrap;
	gap: 8px;
}

.tag {
	padding: 10px 20px;
	border-radius: 24px;
	background-color: #2a2a33;
	cursor: pointer;
	transition: all 0.2s ease;
	font-size: 14px;
	color: #fff;
	font-style: normal;
	font-weight: 700;
	letter-spacing: 0.5px;
	display: flex;
	align-items: center;
	gap: 4px;
}

.removeTag {
	background: none;
	border: none;
	color: rgba(255, 255, 255, 0.5);
	cursor: pointer;
	padding: 0;
	font-size: 16px;
	line-height: 1;
}

.removeTag:hover {
	color: rgba(255, 255, 255, 0.8);
}
