.block {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	width: 100%;
}

.blockTitle {
	color: #fff;
	font-family: Inter;
	font-size: 16px;
	font-style: normal;
	font-weight: 700;
	line-height: 24px;
	/* 150% */
	letter-spacing: 0.374px;
	margin: 0;
	margin-left: 12px;
}

.blockContent {
	width: 100%;
	display: flex;
	flex-direction: column;
	gap: 12px;
}

.collapsible {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	width: 100%;
	margin-top: 12px;

	border-radius: 16px;
	border: 1px solid #292841;
	background: #212034;

	padding: 12px;
	gap: 12px;
	align-self: stretch;
}

.injected {
	padding: 0;
	border: none;
	margin-top: 20px;
}

.injected .blockTitle {
	margin-left: 0;
}

.collapsibleHeader {
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
}

.collapsibleProgress {
	display: flex;
	align-items: center;
}

.arrow {
	width: 24px;
	height: 24px;
	transform: rotate(180deg);
	margin-left: 12px;
	cursor: pointer;
}

.arrowOpen {
	transform: rotate(0deg);
}

.textArea {
	background-color: unset;
	background: linear-gradient(0deg, #191827 0%, #191827 100%), #191827;
}

.addExampleButton:global(.inbutton) {
	width: 100% !important;
	margin-top: 12px !important;
	padding: 9px 20px !important;
	height: 40px !important;
	margin-bottom: 30px !important;
}

.error {
	position: absolute;
	color: red;
	font-size: 14px;
	bottom: 88px;
}
