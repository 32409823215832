.container {
	display: flex;
	flex-wrap: wrap;
	gap: 20px;
	display: grid;
	grid-template-columns: repeat(auto-fill, minmax(190px, 1fr));
	margin-top: 24px;
}

@media (max-width: 960px) {
	.container {
		padding: 0 20px;
		box-sizing: border-box;
		grid-template-columns: repeat(auto-fill, minmax(134px, 1fr));
	}
}

.masonry {
	display: flex;
	gap: 16px;
	padding: 16px 16px 40px 16px;
}

.masonryColumn {
	flex: 1;
	display: flex;
	flex-direction: column;
	gap: 16px;
	align-items: center;
}
