.container {
	width: 100%;
	display: flex;
	box-sizing: border-box;

	flex-direction: column;
	padding: 20px 20px 20px 20px;
	border: 1px solid #292841;

	background: #212034;
	border-radius: 16px;
	position: relative;
	height: 200px;
	color: white;
	margin-bottom: 40px;
	margin-top: 40px;
}

.container :global(.text-area__container) {
	height: 150px;
}

.textarea {
	background: #212034;
	padding: 0;
	width: 100%;
	height: 110px;
	font-family: Inter;
	border-radius: 0;
}

.textarea:focus,
.textarea:focus-visible {
	outline: none;
}

.textarea::placeholder {
	color: #5a5976;
}

.suggestions {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: space-around;
	height: 40px;
	overflow: hidden;
}

.suggestions :global(.inbutton.MuiButton-outlined) {
	color: #9c85f6;
	border-color: #9c85f6;
	box-sizing: border-box;
	margin-bottom: 10px;
	margin-right: 4px;
}

.suggestions :global(.inbutton.MuiButton-outlined):hover {
	color: white;
	border-color: #9c85f6;
	border-width: 1px;
}

.textarea::-webkit-scrollbar {
	width: 8px;
	height: 5px;
}

.textarea::-webkit-scrollbar-track {
	border-radius: 20px;
}

.textarea::-webkit-scrollbar-thumb {
	background-color: #191827;
	border: 1px solid #191827;
	border-radius: 20px;
}

.suggestionActive {
	background-color: #9c85f6 !important;
	color: white !important;
}
